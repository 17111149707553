import { get, put, post, del } from "./base";

// 禁言记录
export function getProhibition(data) {
  return get("/api/admin/shutUps", data);
}
// 根据id解除禁言
export function removeProhibition(id) {
  return put(`/api/admin/removeShutUpById/${id}`);
}
// 获取用户信息
export function getUserInformation(id) {
  return get(`/api/admin/userInfo/${id}`);
}
// 获取黑名单
export function getBlacklist(data) {
  return get("/api/admin/blackList", data);
}
// 加入黑名单
export function InsertBlacklist(data) {
  return post("/api/admin/insertBlackList", data);
}
// 根据id解除黑名单
export function removeBlackList(id) {
  return put(`/api/admin/removeBlackById/${id}`);
}
// 获取用户列表
export function getUser(data) {
  return get("/api/admin/userBlackList", data);
}
