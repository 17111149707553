<template>
  <div style="width: 100%; height: 100%; border: 10px solid #edeff2">
    <div class="top">
      <div>
        <span>用户名称：</span>
        <a-input
          v-model="userName"
          placeholder="请输入"
          style="width: 200px; margin-left: $nameInputSpace"
        />
      </div>
      <div>
        <span>手机号：</span>
        <a-input
          v-model="telNumber"
          placeholder="请输入"
          style="width: 200px; margin-left: $nameInputSpace"
        />
      </div>
      <div>
        <span>时间：</span>
        <a-range-picker
          @change="timeChange"
          v-model="year"
          style="width: 320px; margin-left: $nameInputSpace"
        />
      </div>
      <div>
        <a-button type="primary" @click="search">查询</a-button>
        <a-button @click="clear" style="margin-left: 10px">重置</a-button>
      </div>
    </div>
    <div style="width: 100%; height: 10px; background: #edeff2"></div>
    <div class="table">
      <!-- <div style="border-bottom: 1px solid #edeff2; margin-bottom: 15px">
        <div class="titleCard">
          <div style="font-weight: bold; margin-top: 5px; color: $topNameColor">
            黑名单
          </div>
          <a-button @click="add" type="primary" style="width: 100px">
            +加入黑名单</a-button
          >
        </div>
      </div> -->
      <div class="liebiao">
        <div class="title">
          <div>黑名单记录</div>
          <div>
            <a-button @click="add" type="primary" style="width: 100px">
              +加入黑名单</a-button
            >
          </div>
        </div>
      </div>
      <div class="table-body">
        <a-table
          :pagination="false"
          :columns="columnsAdd"
          :dataSource="dataSource"
          :scroll="{ x: 1500 }"
        >
          <!-- 名字 -->
          <span slot="name" slot-scope="text, record, index">
            <a @click="Open(record)"> {{ text }}</a>
          </span>
          <!-- 操作 -->
          <span slot="is_operation" slot-scope="text, record, index">
            <a style="width: 100%; height: 100%; display: block">
              <a-popconfirm
                title="确定移除?"
                ok-text="是"
                cancel-text="否"
                @confirm="remove(record)"
                @cancel="cancel"
              >
                <a style="font-weight: 550">移除</a>
              </a-popconfirm>
            </a>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 分页 -->
    <div
      style="
        display: block;
        width: 100%;
        height: 50px;
        text-align: center;
        margin-top: 45px;
      "
    >
      <a-pagination
        :current="curPage"
        :total="total"
        :pageSize="pageSize"
        show-quick-jumper
        @change="PageNOchange"
        style="display: inline-block"
      />
    </div>
    <!-- 弹窗 -->
    <a-drawer
      :title="openForm.name"
      placement="right"
      @close="close"
      :visible="visible"
      :get-container="false"
      width="860"
      style="overflow-y: scroll"
    >
      <a-descriptions title="账号信息" class="descriptions">
        <a-descriptions-item label="用户名称">
          {{ this.openForm.name }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号">
          {{ this.openForm.tel }}
        </a-descriptions-item>
        <a-descriptions-item label="所在医院">
          {{ this.openForm.hospitalName }}
        </a-descriptions-item>
        <a-descriptions-item label="科室">
          {{ this.openForm.hospitalOffices }}
        </a-descriptions-item>
        <a-descriptions-item label="职称">
          {{ this.openForm.jobTitle }}
        </a-descriptions-item>
        <a-descriptions-item>
          <span
            style="margin-right: 10px; color: blue; cursor: pointer"
            v-if="recordForm.isBeInBlack == 0"
            @click="addBlacklist(recordForm)"
            >加入黑名单</span
          >
          <span
            style="margin-right: 10px; color: blue; cursor: pointer"
            v-if="recordForm.isBeInBlack == 1"
            >已加入黑名单</span
          >

          <span style="color: red">已禁言{{ count }}次</span>
        </a-descriptions-item>
      </a-descriptions>
      <!-- 禁言记录 -->
      <div class="Prohibition">
        <div class="liebiao">
          <div class="title">禁言记录</div>
        </div>
        <a-table
          :pagination="false"
          :columns="columnsProhibition"
          :dataSource="Prohibition"
          :scroll="{ y: 270 }"
        >
          <!-- <span slot="isBeInShutUp" slot-scope="text, record, index">
            <span v-if="record.isBeInShutUp == 0">恢复</span>
            <span v-if="record.isBeInShutUp == 1">禁言</span>
          </span> -->
          <span slot="isBeInShutUp" slot-scope="text, record, index">
            <span>禁言</span>
          </span>
          <span slot="noBeInShutUp" slot-scope="text, record, index">
            <span v-if="record.isBeInShutUp == 1">否</span>
            <span v-else>是</span>
          </span>
        </a-table>
      </div>
      <!-- 黑名单记录 -->
      <div class="blackList">
        <div class="liebiao">
          <div class="title">黑名单记录</div>
        </div>
        <a-table
          :pagination="false"
          :columns="columnsblackList"
          :dataSource="blackList"
          :scroll="{ y: 300 }"
        >
          <span slot="isBeInBlack" slot-scope="text, record, index">
            <span>拉黑</span>
          </span>
          <span slot="noBeInBlack" slot-scope="text, record, index">
            <span v-if="record.isBeInBlack == 1">否</span>
            <span v-else>是</span>
          </span>
        </a-table>
      </div>
    </a-drawer>
    <!-- 原因弹窗 -->
    <a-modal
      title="加入黑名单"
      :visible="reasonvisible"
      @ok="handleOk"
      @cancel="closeBlacklist"
      :width="450"
    >
      <div style="font-size: 14px; margin-top: -10px">是否确认加入黑名单?</div>
      <div style="color: red; margin-top: 10px">
        加入黑名单后将无法观看该企业下所有会议
      </div>
      <a-select
        placeholder="请选择"
        class="postSpeakSty"
        v-if="blackReason !== '其他'"
        v-model="blackReason"
      >
        <a-select-option
          v-for="list in postSpeakList"
          :key="list.id"
          :value="list.title"
          class="postSpeakSty"
        >
          {{ list.title }}
        </a-select-option>
      </a-select>
      <a-input
        v-else
        placeholder=" 请输入加入黑名单原因"
        class="postSpeakSty"
        v-model="otherReason"
      ></a-input>
    </a-modal>

    <!-- 加入黑名单弹窗 -->
    <a-drawer
      title="加入黑名单"
      placement="right"
      @close="addclose"
      :visible="addShow"
      :get-container="false"
      width="700"
    >
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin-bottom: 20px;
        "
      >
        <div>
          <span style="color: $topTitleColor">用户名称：</span
          ><a-input
            placeholder="请输入"
            style="width: 200px; margin-left: 5px"
            v-model="addForm.userName"
          ></a-input>
        </div>
        <div>
          <span style="color: $topTitleColor">手机号：</span
          ><a-input
            placeholder="请输入"
            style="width: 200px; margin-left: 5px"
            v-model="addForm.telNumber"
          ></a-input>
        </div>
        <a-button type="primary" @click="addSearch">查询</a-button>
      </div>
      <a-table
        :pagination="false"
        :columns="addList"
        :dataSource="addblackList"
        :scroll="{ y: 490 }"
      >
        <span slot="is_operation" slot-scope="text, record, index">
          <a v-if="record.isBeInBlack == 0" @click="addBlacklist(record)"
            >选择</a
          >
          <a v-else style="color: red">已选择</a>
        </span>
      </a-table>
    </a-drawer>
  </div>
</template>

<script>
import {
  getBlacklist,
  getUserInformation,
  getProhibition,
  InsertBlacklist,
  removeBlackList,
  getUser,
} from "../../../service/blackList";
export default {
  name: "blackList",
  data() {
    return {
      dataSource: [],
      year: [],
      userName: "",
      telNumber: "",
      curPage: 1,
      pageSize: 10,
      total: 0,
      count: 0,
      startTime: "",
      endTime: "",
      visible: false,
      openForm: {},
      recordForm: {},
      addForm: {},
      reason: "",
      form: {},
      reasonvisible: false,
      addShow: false,
      blackReason: undefined, //下拉原因
      otherReason: "", //输入其他原因
      columnsAdd: [
        {
          title: "用户名称",
          dataIndex: "name",
          width: "10%",
          key: "name",
          align: "left",
          scopedSlots: { customRender: "name" },
        },

        {
          title: "用户手机号",
          dataIndex: "tel",
          width: "12%",
          key: "tel",
          align: "left",
        },

        {
          title: "所属医院",
          dataIndex: "hospitalName",
          width: "17%",
          key: "hospitalName",
          align: "left",
        },
        {
          title: "科室",
          dataIndex: "hospitalOffices",
          width: "12%",
          key: "hospitalOffices",
          align: "left",
        },
        {
          title: "职称",
          dataIndex: "jobTitle",
          width: "10%",
          key: "jobTitle",
          align: "left",
        },
        {
          title: "加入黑名单理由",
          dataIndex: "reason",
          width: "16%",
          key: "reason",
          align: "left",
        },
        {
          title: "加入黑名单时间",
          dataIndex: "createdTime",
          width: "13%",
          key: "createdTime",
          align: "left",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "is_operation" },
          key: "operation",
          align: "left",
          fixed: "right",
          width: 150,
        },
      ],
      Prohibition: [],
      columnsProhibition: [
        {
          title: "类型",
          dataIndex: "InShutUp",
          width: "10%",
          key: "InShutUp",
          align: "left",
          scopedSlots: { customRender: "isBeInShutUp" },
        },
        {
          title: "原因",
          dataIndex: "reason",
          width: "18%",
          key: "reason",
          align: "left",
        },
        {
          title: "时间",
          dataIndex: "createdTime",
          width: "14%",
          key: "createdTime",
          align: "left",
        },
        {
          title: "所属直播间",
          dataIndex: "streamName",
          width: "17%",
          key: "streamName",
          align: "left",
        },
        {
          title: "解除禁言",
          dataIndex: "NOShutUp",
          width: "10%",
          key: "NOShutUp",
          align: "left",
          scopedSlots: { customRender: "noBeInShutUp" },
        },
        {
          title: "解除时间",
          dataIndex: "updatedTime",
          width: "14%",
          key: "updatedTime",
          align: "left",
        },
      ],
      blackList: [],
      columnsblackList: [
        {
          title: "类型",
          dataIndex: "InBlack",
          width: "15%",
          key: "InBlack",
          align: "left",
          scopedSlots: { customRender: "isBeInBlack" },
        },
        {
          title: "原因",
          dataIndex: "reason",
          width: "20%",
          key: "reason",
          align: "left",
        },
        {
          title: "时间",
          dataIndex: "createdTime",
          width: "20%",
          key: "createdTime",
          align: "left",
        },
        {
          title: "解除黑名单",
          dataIndex: "noInBlack",
          width: "15%",
          key: "noInBlack",
          align: "left",
          scopedSlots: { customRender: "noBeInBlack" },
        },
        {
          title: "解除时间",
          dataIndex: "updatedTime",
          width: "20%",
          key: "updatedTime",
          align: "left",
        },
      ],
      addblackList: [],
      addList: [
        {
          title: "用户名称",
          dataIndex: "name",
          width: "20%",
          key: "name",
          align: "left",
        },
        {
          title: "手机号",
          dataIndex: "tel",
          width: "20%",
          key: "tel",
          align: "left",
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          width: "20%",
          key: "hospitalName",
          align: "left",
        },
        {
          title: "科室",
          dataIndex: "hospitalOffices",
          width: "20%",
          key: "hospitalOffices",
          align: "left",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "is_operation" },
          key: "operation",
          align: "left",
        },
      ],

      addRules: {
        reason: [
          { required: true, message: "请输入原因", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[^\s]+[\s]*.*$/i.test(value) == false) {
                callback(new Error("请输入正确的原因"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      postSpeakList: [
        {
          id: 1,
          title: "发言含有低俗/不雅词汇",
        },
        {
          id: 2,
          title: "发言含有挑衅词汇",
        },
        {
          id: 3,
          title: "发言含有侮辱性词句",
        },
        {
          id: 4,
          title: "刷屏",
        },
        {
          id: 5,
          title: "其他",
        },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    // 获取黑名单列表
    async load() {
      let data = {
        name: this.userName,
        tel: this.telNumber,
        curPage: this.curPage,
        pageSize: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
        isBeInBlack: 1,
      };
      // console.log(data);
      const res = await getBlacklist(data);
      if (res.code == 0) {
        // console.log(res.data);
        this.dataSource = res.data;
        this.total = res.count;
      }
    },
    // 查询
    search() {
      this. curPage=1
      this.load();
    },
    cancel() {},
    // 点击时间选择框
    timeChange(date, dateString) {
      this.year = dateString;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    // 点击重置
    clear() {
      this.year = [];
      this.telNumber = "";
      this.userName = "";
      this.startTime = "";
      this.endTime = "";
      this.load();
    },
    // 改变页码
    PageNOchange(curPage) {
      this.curPage = curPage;
      this.load();
    },
    // 打开弹窗
    async Open(record) {
      this.recordForm = record;
      const res = await getUserInformation(record.memberId); //获取用户个人信息
      if (res.code == 0) {
        this.openForm = res.data;
        // console.log("openForm", this.openForm);
      }
      this.visible = true;
      this.getProhibitionList(record.memberId);
      this.getblackList(record.memberId);
    },
    // 关闭弹窗
    close() {
      this.visible = false;
    },
    // 根据id获取禁言记录
    async getProhibitionList(memberId) {
      let data = {
        memberId: memberId,
        pageSize: 9999,
        curPage: 1,
      };
      const res = await getProhibition(data);
      if (res.code == 0) {
        this.Prohibition = res.data;
        this.count = this.Prohibition.length;
      }
    },
    // 根据id获取黑名单记录
    async getblackList(memberId) {
      let data = {
        memberId: memberId,
        pageSize: 9999,
        curPage: 1,
      };
      const res = await getBlacklist(data);
      if (res.code == 0) {
        this.blackList = res.data;
      }
    },
    // 点击选择
    async addBlacklist(record) {
      this.reasonvisible = true;
      this.category = record;
    },
    // 黑名单确定
    async handleOk() {
      if (this.blackReason) {
        let data = {};
        if (this.blackReason !== "其他") {
          data = {
            memberId: this.category.id,
            reason: this.blackReason,
          };
        } else {
          data = {
            memberId: this.category.id,
            reason: this.otherReason,
          };
        }
        // console.log("99", data);
        const res = await InsertBlacklist(data);
        if (res.code == 200) {
          this.$message.success("加入成功");
          this.load();
          this.closeBlacklist();
          this.addSearch();
        } else {
          this.$message.warning("加入失败");
          this.load();
          this.closeBlacklist();
        }
      } else {
        this.$message.warning("请选择或者输入加入黑名单理由！");
      }
    },
    // 加入黑名单弹窗
    closeBlacklist() {
      this.blackReason = undefined;
      this.otherReason = "";
      this.reasonvisible = false;
    },

    // 根据id移除黑名单
    async remove(record) {
      const res = await removeBlackList(record.id);
      if (res.code == 200) {
        this.$message.success("移除成功");
        this.load();
      } else {
        this.$message.warning("移除失败");
      }
    },
    add() {
      this.addShow = true;
    },
    // 加入黑名单搜索
    async addSearch() {
      let data = {
        name: this.addForm.userName,
        tel: this.addForm.telNumber,
        curPage: 1,
        pageSize: 9999,
      };
      const res = await getUser(data);
      if (res.code == 0) {
        this.addblackList = res.data;
      }
    },
    addclose() {
      this.addShow = false;
      this.addForm = {};
      this.addblackList = [];
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  margin: auto;
  height: 70px;
  width: $space_div;
  line-height: 70px;
  display: flex;
  justify-content: space-between;

  span {
    color: $topTitleColor;
  }
}
.blackList {
  margin-top: 25px;
}
.table {
  width: 100%;
  min-height: 400px;
  .titleCard {
    display: flex;
    justify-content: space-between;
    width: $space_div;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;

    margin: auto;
  }
  .table-body {
    width: 98.1%;
    margin: auto;
    margin-top: 20px;
  }
}
.postSpeakSty {
  //width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: 500;
}
.Prohibition ::v-deep .ant-table-tbody > tr > td {
  padding: 10px;
}
.blackList ::v-deep .ant-table-tbody > tr > td {
  padding: 15px;
}
::v-deep .ant-descriptions-title {
  color: $topNameColor;
  font-weight: bold;
  font-size: 14px;
}
.descriptions {
  width: 100%;
  height: 140px;
  border-bottom: 2px solid #e9e9e9;
  font-size: 16px;
}
.liebiao {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(238, 233, 233);
  .title {
    color: $topNameColor;
    font-weight: bold;
    // padding-top: $LineSpacing;
    // padding-bottom: $LineSpacing;
    width: $space_div;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
}
</style>
